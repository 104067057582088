<template>
  <div id="modal-customizado-valor">
    <div class="modal-bg">
      <div class="modal-fechar" @click.exact="fecharModal"></div>
      <div class="modal-conteudo">
        <div class="conteudo-tabela conteudo-interno">
          <h2>Adicionar Produtos</h2>
          <section class="container-tabela">
            <div class="tabela-informacoes" key="Lista" v-if="listaProdutos.length > 0">
              <div class="tabela-linha tabela-linha-titulo">
                <div class="tabela-linha-nome">
                  <p>Produto</p>
                </div>
                <div class="tabela-linha-descricao">
                  <p>Quantidade de Produtos</p>
                </div>
                <div class="tabela-linha-descricao">
                  <p>Quantidade de Pontos</p>
                </div>
                <div class="tabela-linha-descricao">
                  <p>Descrição</p>
                </div>
                <div class="tabela-linha-btns">
                  <p></p>
                </div>
              </div>
              <div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in listaProdutos" :key="item.id">
                <div class="tabela-linha-nome">
                  <p :id="`produto-nome-${item.id}`">{{ item.nome }}</p>
                </div>
                <div class="tabela-linha-descricao">
                  <transition mode="out-in" name="fade">
                    <p v-if="!produtoAtivo[index]">-</p>
                    <div v-else class="formulario-item">
                      <input type="tel" v-model="produtosQuantidade[index]" />
                    </div>
                  </transition>
                </div>
                <div class="tabela-linha-descricao">
                  <transition mode="out-in" name="fade">
                    <p v-if="!produtoAtivo[index]">-</p>
                    <div v-else class="formulario-item">
                      <input type="tel" v-model="produtosValorBonus[index]" />
                    </div>
                  </transition>
                </div>
                <div class="tabela-linha-descricao">
                  <transition mode="out-in" name="fade">
                    <p v-if="!produtoAtivo[index]">-</p>
                    <div v-else class="formulario-item">
                      <textarea v-model="produtosDescricao[index]"></textarea>
                    </div>
                  </transition>
                </div>
                <div class="tabela-linha-btns" @click="clickProduto(index, item.id)">
                  <div class="tabela-linha-btns-incluir">
                    <input :id="`adicionar-produto-${item.id}`" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="conteudo-vazio">
              <p>Nenhum produto cadastrado</p>
            </div>
          </section>
          <div class="container-btns">
            <button class="btn btn-laranja" :disabled="produtosSelecionados.length == 0 || !validacaoObjeto"
              @click="salvar">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buscarProdutoCliente } from "@/services/produto";
export default {
  name: "modalProdutos",
  data() {
    return {
      produtosSelecionados: [],
      idProdutos: [],
      produtosNome: [],
      produtosDescricao: [],
      produtosQuantidade: [],
      produtosValorBonus: [],
      produtoAtivo: [],
      //
      limparObjetoProdutos: [],
      listaProdutos: [],
    };
  },
  computed: {
    validacaoObjeto() {
      return this.limparObjetoProdutos.every((item) => item.ativo);
    },
    dadosValor() {
      return this.$store.state.promocoes.modalPromocaoValor;
    },
  },
  methods: {
    fecharModal() {
      this.$store.commit("SET_MODAL_CUSTOMIZADO", "");
    },
    clickProduto(index, produto) {
      const indexProduto = this.produtosSelecionados.indexOf(produto);
      if (indexProduto == -1) {
        const nome = document.querySelector(`#produto-nome-${produto}`).innerText;
        this.produtosNome[index] = nome;
        this.produtosSelecionados.push(produto);
        this.idProdutos[index] = produto;
        this.produtoAtivo[index] = true;
        document.querySelector(`#adicionar-produto-${produto}`).checked = true;
      } else {
        this.produtosSelecionados.splice(indexProduto, 1);
        document.querySelector(`#adicionar-produto-${produto}`).checked = false;
        this.idProdutos[index] = null;
        this.produtosNome[index] = null;
        this.produtosDescricao[index] = null;
        this.produtosQuantidade[index] = null;
        this.produtosValorPontos[index] = null;
        this.produtoAtivo[index] = false;
      }
    },
    salvar() {
      this.$store.commit("promocoes/SET_PROMOCAO_VALOR", this.limparObjetoProdutos);
      this.$store.commit("SET_MODAL_CUSTOMIZADO", "");
    },
    filtroObjeto() {
      const data = this.idProdutos
        .map((item, index) => {
          return {
            id: item,
            nome: this.produtosNome[index],
            descricao: this.produtosDescricao[index],
            quantidade: this.produtosQuantidade[index],
            valorBonus: this.produtosValorBonus[index],
            ativo: this.produtosDescricao[index] ? true : false,
          };
        })
        .filter((item) => item && item.id);
      this.limparObjetoProdutos = data;
    },
  },
  mounted() {
    buscarProdutoCliente(this.dadosValor.idCliente)
      .then((resp) => {
        this.listaProdutos = resp.data;
      })
      .finally(() => {
        if (this.dadosValor.produtos.produtos.length > 0) {
          const ids = [];
          this.dadosValor.produtos.produtos.forEach((item) => {
            document.querySelector(`#adicionar-produto-${item.id}`).checked = true;
            ids.push(item.id);
          });
          this.produtosSelecionados = ids;
          this.listaProdutos.forEach((item, index) => {
            if (this.produtosSelecionados.includes(item.id)) {
              const itemSelecionado = this.dadosValor.produtos.produtos.filter((item2) => item2.id == item.id);
              this.idProdutos[index] = itemSelecionado[0].id;
              this.produtosNome[index] = itemSelecionado[0].nome;
              this.produtosDescricao[index] = itemSelecionado[0].descricao;
              this.produtosQuantidade[index] = itemSelecionado[0].quantidade;
              this.produtosValorBonus[index] = itemSelecionado[0].valorBonus;
              this.produtoAtivo[index] = true;
            }
          });
        }
      });
  },
  watch: {
    produtosSelecionados() {
      this.filtroObjeto();
    },
    idProdutos() {
      this.filtroObjeto();
    },
    produtosDescricao() {
      this.filtroObjeto();
    },
    produtosQuantidade() {
      this.filtroObjeto();
    },
    produtosValorBonus() {
      this.filtroObjeto();
    },
  },
  beforeDestroy() {
    this.listaProdutos = [];
  },
};
</script>

<style lang="scss">
#modal-customizado-valor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 50px 25px 70px 25px;

  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .modal-bg {
    position: relative;
    z-index: 1;
    margin: auto;

    @media (max-width: 800px) {
      height: 100%;
    }

    &:before {
      content: "";
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
    }

    .modal-fechar {
      position: absolute;
      z-index: 1;
      top: 8px;
      right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        content: "";
        border-radius: 20px;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal-conteudo {
      position: relative;
      width: 100%;

      @media (max-width: 800px) {
        height: 100%;
      }
    }
  }

  .conteudo-vazio {
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 20px;
    }
  }

  .conteudo-tabela {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    border-radius: 15px;
    max-width: 1110px;
    height: auto;
    max-height: 400px;
    padding: 30px 20px 50px 20px;
    text-align: center;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);

    h2 {
      font-size: 35px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    @media (max-width: 800px) {
      min-height: 0px;
      width: 100vw;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }

  .container-tabela {
    overflow: auto;
    height: 240px;

    @media (max-width: 800px) {
      height: calc(100% - 150px);
    }
  }

  .tabela-informacoes {
    width: 100%;
    height: 100%;
    margin-left: 0;

    .tabela-linha-informacoes {
      min-height: 60px;

      .tabela-linha-btns-incluir,
      input[type="checkbox"] {
        cursor: pointer;
      }
    }
  }

  .tabela-linha-btns {
    min-width: 50px;
    max-width: 50px;
  }

  .formulario-item {
    margin-bottom: 0;
    padding: 0;

    input {
      text-align: center;
    }
  }
}
</style>
