import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { tokenExpirado } from '@/utils/tokenExpirado';
//
export function listarProduto(filters, pag = 1) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
    .post(`${_rotasAPI().listarProduto}?pagina=${pag}&quantidadePorPagina=${15}`, filters, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit('produto/SET_CARREGADO', true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function buscarProduto(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().buscarProduto}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function obterPontuacaoProdutosDoCupom(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(_rotasAPI().obterPontuacaoProdutosDoCupom, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("cupom/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}
export function buscarProdutoCliente(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().buscarProdutoCliente}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function cadastrarProduto(data) {
  return new Promise((resolve) => {
    // data.id = 0;
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().cadastrarProduto, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function editarProduto(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .put(_rotasAPI().editarProduto, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}